exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-cookies-js": () => import("./../../../src/pages/politica-de-cookies.js" /* webpackChunkName: "component---src-pages-politica-de-cookies-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-zona-jsx": () => import("./../../../src/templates/zona.jsx" /* webpackChunkName: "component---src-templates-zona-jsx" */)
}

